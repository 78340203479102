import React, {Component,props} from "react";
import Matrix from "./Matrix";
import './css/matrix.css';

// import {fetchLeaders,createLeader} from '../../../LeaderboardAPI2'

import StartModal from "./StartMessage";
import GameFinished from "./EndMessage";
import { mConstants } from "./common/fconstants";

class Main extends Component {
   constructor(props){
        super(props);
        this.state = {
            startshow: true,
            gameEnd:false,
            counter: 0
        }
  }

  // setLeaderboard = async () => {
  //  //await createLeader("kapil sss1" , "dd@d26.com",223223,5)
  //    await fetchLeaders();
  // } 

  componentDidMount() {
      // this.setLeaderboard();
  }

  handleMessageClose = () => this.setState({startshow:false});
  handleGameFinish = (show) => {
    console.log("handle Game finish called" + show);
    this.setState({gameEnd:show});
    this.setState((prevState, props)=> ({
      counter:prevState.counter + 1
  }))

  };
   
  render(){
    return (
      <React.Fragment>
         <Matrix key={this.state.counter}
         gameFinish={this.handleGameFinish} classname="Matrix" rows="7" cols="5" />
         
         <StartModal show={this.state.startshow} handleClose={this.handleMessageClose}  /> 
         <GameFinished show={this.state.gameEnd} 
                  handleClose={() => this.handleGameFinish(false)}  />
      </React.Fragment>  
    );
  }
}

export default Main;