import React, {useState} from 'react';
import './App.css';
import Modal from "react-modal";

import Menu from './Components/Menu';
import FlyGame from './Views/FlyGame';



import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';



Modal.setAppElement("#root");

function App() {



  return (
    <div className="App">
        <Router>
          <div className="row heading">
              <div className="col"> <h3> <img style={{width:"40px"}} 
              src="/images/logo.jpg"/> 
              <a href="https://www.edbetter.uk/"><sub>EdBetter</sub></a>    </h3>
              </div>
            </div>
            <h1>Katch Perries</h1> 
            <Routes>
                  <Route  path="/" element={<FlyGame/>} />
            </Routes>
        </Router>
    </div>
  );
}



export default App;
