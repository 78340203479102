import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { mConstants } from './common/fconstants';
import { Row,Col } from 'react-bootstrap';
import './css/modals.css'
// import SaveScore from './SaveScore';
// import Leaderboard from './Leaderboard';
import './css/leader.css'

class GameFinished extends Component {
    state = { 
        scoreshow:false,
        leadershow:false
     } 

     
    showLeader = () => {
      this.setState({leadershow:true})
    }

    handleLeaderClose = () => {
      this.setState({leadershow:false})
    }


    message = () => {
        if(!mConstants.result || mConstants.result.butterfly==0) {
          return "Sorry, no butterflies.  Try again!"
        }else{
          return "Well played.  Try more!"
          
        }
    }

    showScoreModal = () => {
       this.setState(
        {scoreshow:true}
        )
    }

    handleSaveClose = () => {
      this.setState({scoreshow:false})
    };

    showSaveButton = () => {
      if(mConstants.result && 
        mConstants.result.butterfly > 0) {
          return   <Col>
            <Button variant="success" onClick={this.showScoreModal}>
              <span className="record">Save Score</span>
            </Button>
          </Col> 
      }else{
        return <Col>&nbsp;</Col>;
      }
    }

    render() { 
        return (<><Modal id="endModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em"> Game Finished </span></Modal.Title>
            <Button onClick={this.showLeader} variant="link" className="leaderBtn"> 
                            <img style={{width:"40px"}} 
                            src="../images/leaderboard-01.png"/> </Button>
                
          </Modal.Header>
          <Modal.Body>
                <Row>
                  <Col  className="text-end"> <img src="../images/butterfly_01.png" style={{width:"30px"}}/></Col>  
                  <Col  className="text-start">
                  {mConstants.result && 
                    mConstants.result.butterfly?mConstants.result.butterfly:0}
                    </Col>  
                    
                </Row>
                <Row>
                  <Col className="text-end">Score</Col>
                  <Col className="text-start"><span className="em"> {mConstants.result && 
                      mConstants.result.score?mConstants.result.score:0}</span></Col>
                 
                </Row>

                <Row>
                  <Col  className="text-center">
                      {this.message()}
                    </Col>  
                    
                </Row>
                
                    
          </Modal.Body>
           <Modal.Footer >
            <Row style={{width:"100%"}}>
                {this.showSaveButton()}  
                 <Col>
                    <Button variant="warning" onClick={this.props.handleClose}>
                      <span className="play">Play Again</span>
                    </Button>
                 </Col>   
            </Row>

            {/* <Button variant="warning" onClick={this.props.handleClose}>
                 <span className="play">Leaderboard</span>
            </Button> */}
          </Modal.Footer>
          
        </Modal>
            {/* <SaveScore show={this.state.scoreshow} 
                    handleClose={this.handleSaveClose}  /> 
            <Leaderboard show={this.state.leadershow} 
            handleClose={this.handleLeaderClose}  />  */}
     </>
        );
    }
}
 
export default GameFinished;